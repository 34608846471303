import { render, staticRenderFns } from "./SmallProductCard.vue?vue&type=template&id=4e5c5c97&scoped=true&"
import script from "./SmallProductCard.vue?vue&type=script&lang=js&"
export * from "./SmallProductCard.vue?vue&type=script&lang=js&"
import style0 from "./SmallProductCard.vue?vue&type=style&index=0&id=4e5c5c97&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5c5c97",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,HepLogo: require('/vercel/path0/components/general/HepLogo.vue').default,ProductPrice: require('/vercel/path0/components/product/ProductPrice.vue').default,ArrowLeft: require('/vercel/path0/components/icons/ArrowLeft.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
