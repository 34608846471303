
export default {
  props: {
    color: {
      type: [Object, String],
      default: '',
    },

    whiteFallback: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validBgColors() {
      return [
        'bg-palette-gray-dark',
        'bg-palette-gray-light',
        'bg-palette-teal-dark',
        'bg-palette-teal-light',
        'bg-palette-blue-dark',
        'bg-palette-blue',
        'bg-palette-blue-light',
        'bg-palette-green-dark',
        'bg-palette-green-light',
        'bg-palette-mint',
        'bg-palette-pink',
        'bg-palette-rosa',
        'bg-palette-red',
        'bg-palette-yellow',
        'bg-palette-white',
      ]
    },

    bgColorClass() {
      if (this.whiteFallback) {
        return (
          this.validBgColors.find(
            (color) => color === 'bg-palette-' + this.bgColor
          ) || 'bg-palette-white'
        )
      } else {
        return (
          this.validBgColors.find(
            (color) => color === 'bg-palette-' + this.bgColor
          ) || 'bg-palette-gray-light'
        )
      }
    },

    isNegative() {
      return !['bg-palette-white', 'bg-palette-gray-light'].includes(
        this.bgColorClass
      )
    },

    isBgGray() {
      return this.bgColorClass === 'bg-palette-gray-light'
    },

    bgColor() {
      return this.color?.key || this.color
    },
  },
}
