
import ProductFunctions from '~/mixins/ProductFunctions'

export default {
  mixins: [ProductFunctions],

  props: {
    product: {
      type: Object,
      required: true,
    },

    bgWhite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isHover: false,
    }
  },

  computed: {
    hasAuthors() {
      return this.product.authors?.length
    },

    firstAuthor() {
      return this.hasAuthors ? this.product.authors[0] : null
    },
  },
}
